
export default function(state = {testSets: [], testSetIndex: 0, imgIndex: 0, images: {croppedImageUrls: [], croppedImageFiles: [], croppedImageDataURLS: []}, indexOfImageToReplace: null }, action) {
  switch (action.type) {
    case 'CREATE_TESTSET':
      // const testSetIndex = state.testSetIndex + 1
      // window.localStorage.setItem('storageState', JSON.stringify({
      //   ...state,
      //   testSets: [...state.testSets, action.payload]
      // }))
      return {
        ...state,
        testSets: [...state.testSets, action.payload],
        isEditing: false
        // testSetIndex: testSetIndex
      }
    case 'DELETE_TESTSET':
      const testSets = state.testSets
      const testSetIndexToRemove = action.payload
      const testSets1 = testSets.slice(0, testSetIndexToRemove)
      let testSets2 = []
      if (testSets[testSetIndexToRemove + 1]) {
        testSets2 = testSets.slice(testSetIndexToRemove + 1, testSets.length)
      }
      const mergedTestSets = testSets1.concat(testSets2)
      try {
        window.localStorage.setItem('storageState', JSON.stringify({
          ...state,
          testSets: mergedTestSets
        }))
      }
      catch(event) {
        console.log('Local storage is full', event)
      }
      return {
        ...state,
        testSets: mergedTestSets
      }
    case 'STORE_CROPPED_IMAGE':
      const testSet = state.testSets[state.testSetIndex]

      const croppedImageUrls = testSet.croppedImageUrls
      const croppedImageFiles = testSet.croppedImageFiles
      const croppedImageDataURLS = testSet.croppedImageDataURLS

      croppedImageUrls[state.imgIndex] = action.payload.croppedImageUrl
      croppedImageFiles[state.imgIndex] = action.payload.croppedImageFile
      croppedImageDataURLS[state.imgIndex] = action.payload.croppedImageDataURL

      const newTestSets = state.testSets
      newTestSets[state.testSetIndex] = testSet
      if (croppedImageDataURLS.length === 3) {
        try {
          window.localStorage.setItem('storageState', JSON.stringify({
            ...state,
            testSets: newTestSets
          }))
        }
        catch(event) {
          console.log('Local storage is full', event)
        }
      }
      return {
        ...state,
        testSets: newTestSets,
        batchUploadResponse: null
      }
    case 'STORE_NOTES':
      const setWithNotes = state.testSets[state.testSetIndex]
      setWithNotes.notes = action.payload
      const newTestSets2 = state.testSets
      newTestSets2[state.testSetIndex] = setWithNotes
      window.localStorage.setItem('storageState', JSON.stringify({
        ...state,
        testSets: newTestSets2
      }))
      return {
        ...state,
        testSets: newTestSets2
      }
    case 'CLEAR_IMAGES':
        return {
          ...state,
          images: {
            croppedImageUrls: [...state.images.croppedImageUrls, action.payload.croppedImageUrls],
            croppedImageFiles: [...state.images.croppedImageFiles, action.payload.croppedImageFiles]
          }
        }
    case 'REMOVE_IMAGE': {
      const newCroppedImageUrls1 = state.images.croppedImageUrls.slice(0, action.payload)
      const newCroppedImageUrls2 = state.images.croppedImageUrls.slice(action.payload + 1, state.images.croppedImageUrls.length)
      const newCroppedImageUrls = newCroppedImageUrls1.concat(newCroppedImageUrls2)

      const newCroppedImageFiles1 = state.images.croppedImageFiles.slice(0, action.payload)
      const newCroppedImageFiles2 = state.images.croppedImageFiles.slice(action.payload + 1, state.images.croppedImageFiles.length)
      const newCroppedImageFiles = newCroppedImageFiles1.concat(newCroppedImageFiles2)
      return {
        ...state,
        images: {
          croppedImageUrls: newCroppedImageUrls,
          croppedImageFiles: newCroppedImageFiles
        }
      }
    }
    case 'SPLICE_IMAGE': {
      const newCroppedImageUrls = state.images.croppedImageUrls
      const newCroppedImageFiles = state.images.croppedImageFiles
      newCroppedImageUrls.splice(action.payload.index, 1, action.payload.croppedImageUrl)
      newCroppedImageFiles.splice(action.payload.index, 1, action.payload.croppedImageFile)
      return {
        ...state,
        images: {
          croppedImageUrls: newCroppedImageUrls,
          croppedImageFiles: newCroppedImageFiles
        }
      }
    }
    case 'STORE_IMAGE_INDEX': {
      return {
        ...state,
        indexOfImageToReplace: action.payload
      }
    }
    case 'STORE_TESTSET_AND_IMAGE_INDEX': {
      // window.localStorage.setItem('storageState', JSON.stringify({
      //   ...state,
      //   testSetIndex: action.payload.testSetIndex,
      //   imgIndex: action.payload.imgIndex
      // }))
      return {
        ...state,
        testSetIndex: action.payload.testSetIndex,
        imgIndex: action.payload.imgIndex
      }
    }
    case 'STORE_ORIGINAL_IMAGE': {
      const testSet = state.testSets[state.testSetIndex]
      const originalImageUrls = testSet.originalImageUrls ? testSet.originalImageUrls : []
      originalImageUrls[state.imgIndex] = action.payload.url
      const originalImageFiles = testSet.originalImageFiles ? testSet.originalImageFiles : []
      originalImageFiles[state.imgIndex] = action.payload.file
      
      const newTestSet = {...testSet, originalImageUrls, originalImageFiles}
      const testSets = state.testSets
      testSets[state.testSetIndex] = newTestSet
      
      // window.localStorage.setItem('storageState', JSON.stringify({
      //   ...state,
      //   testSets: testSets
      // }))

      return {
        ...state,
        testSets: testSets
      }
    }
    case 'STORE_LOCAL_STORAGE_TEST_SETS': {
      return {
        ...state,
        testSets: action.payload
      }
    }
    case 'STORE_SECRET': {
      return {
        ...state,
        secret: action.payload
      }
    }

    case 'SUBMIT_TEST_SETS': {
      return {
        ...state,
        batchUploadResponse: action.payload
      }
    }

    case 'STORE_PATIENT_DATA': {
      return {
        ...state,
        patientData: action.payload.patientData,
        patientName: action.payload.name,
        patientID: action.payload.id
      }
    }

    case 'STORE_PAYMENT_INTENT': {
      return {
        ...state,
        paymentIntent: action.payload
      }
    }

    case 'STORE_ISEDITING': {
      return {
        ...state,
        isEditing: action.payload
      }
    }
    
    default:
      return state
  }
}