import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import GoodMole from './Images/GoodMole.jpg'
import Deck from './Deck'
import plus from './Plus.svg'
import Loading from './LoadingIcon'
import LoadingIcon2 from './LoadingIcon2'
import { storeTestSetAndImgIndex } from './storeTestSetAndImgIndexAction'
import { deleteTestSet } from './deleteTestSetAction'
import { storeSecret } from './storeSecretAction'
import { storeIsEditing } from './storeIsEditingAction'

import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'

import CheckoutForm from './InjectedCheckoutForm'
// const stripePromise = loadStripe('pk_live_6qYxBeD2Bq5aiC0vuPV6iNVz00LOhL6imJ')

class Collage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      batchId: null,
      submitModal: false,
      deleteModal: false,
      slideUpDelete: false,
      slideUpSubmit: false,
      slideUpCart: false,
      dropDown: {},
      testSetDropDown: -1, // index of testSet to show 'edit' and 'delete options

      isDoneLoading: true,
      secret: ""
    }
  }

  componentDidMount() {
    // window.localStorage.setItem("payStatus", "loading")
    // setInterval(() => {
    //   if (window.localStorage.getItem("payStatus") === "Success") {
    //     this.props.changePage('loadingScreenPage')
    //   }
    // }, 2000)
    this.setState({
      batchId: queryString.parse(window.location.search).batchId,
    })

    const storageStateJSON = window.localStorage.getItem('storageState')
    let storageState
    if (storageStateJSON) {
      storageState = JSON.parse(storageStateJSON)
    }
    // if (storageState) {
    //   console.log('storage state', storageState)
    // }
    const testSets =
      storageState &&
        storageState.testSets &&
        // storageState.testSets.length > 0 ? 
        storageState.testSets.filter(testSet => testSet.croppedImageDataURLS.length === 3) ?
        storageState.testSets.filter(testSet => testSet.croppedImageDataURLS.length === 3) :
        this.props.testSets

    this.getPaymentSecret(testSets.length, queryString.parse(window.location.search).state)
  }

  // componentWillReceiveProps(props) {
  //   if (props.secret === 'success')
  // }

  toggleModal() {
    this.setState({ modal: -1 })
  }

  handleDate(timeStamp) {
    const date = new Date(timeStamp)
    const hour = date.getHours().toString()
    const minutes = date.getMinutes().toString()
    const add0 = (str) => {
      if (str.length === 1) {
        return '0' + str
      } return str
    }
    const time = add0(hour) + ':' + add0(minutes)
    return time
  }

  getPaymentSecret(qty, state) {
    let self = this
    fetch(`https://us-central1-lazarus-cds.cloudfunctions.net/getPaymentIntent?qty=` + qty + `&state=` + state,
      {
        method: 'GET',
      }).then((res) => res.json()
      ).then((res) => {
        console.log('payment secret success')
        if (res["status"] === "Success") {
          self.setState({
            secret: res["secret"]
          })
          this.props.storeSecret(res["secret"])
        } else {
          self.setState({
            secret: ""
          })
          this.props.storeSecret(res["secret"])
        }
      }).catch(() => {
        console.log('payment secret failure')
        self.setState({
          secret: ""
        })
        this.props.storeSecret("")
      })
  }

  handleLoading = (state) => {
    this.setState({
      isDoneLoading: state
    })
  }

  render() {
    // const storageStateJSON = window.localStorage.getItem('storageState')
    // let storageState
    // if (storageStateJSON) {
    //   storageState = JSON.parse(storageStateJSON)
    // }
    // if (storageState) {
    //   console.log('storage state', storageState)
    // }
    // const testSets =
    //   storageState &&
    //     storageState.testSets ?
    //     // storageState.testSets.length > 0 ? 
    //     storageState.testSets.filter(testSet => testSet.croppedImageDataURLS.length === 3) :
    //     this.props.testSets
    const { testSets } = this.props
    const testPrice = 50
    const extraTestPrice = 14
    const testSetsLength = testSets.length
    const extraTests = testSetsLength <= 1 ? 0 : testSetsLength - 1
    const root = document.getElementById('root')
    const fontSize = parseFloat(getComputedStyle(root).fontSize)
    const contentWidth = document.getElementsByClassName('App')[0].offsetWidth
    const btnStyle = { width: `${contentWidth - (fontSize * 2)}px` }
    let height = window.innerHeight

    const stripeThing = () => {
      const adminList = process.env.REACT_APP_ADMINS.split(' ')
      const isAdmin = adminList.some(admin => admin === queryString.parse(window.location.search).state)
      const key = isAdmin ? process.env.REACT_APP_STRIPE_TEST_KEY : process.env.REACT_APP_STRIPE_KEY
      return loadStripe(key)
    }
    window.addEventListener('resize', () => {
      height = window.innerHeight
    })
    return (
      (this.state.isDoneLoading) ? (
        <div
          className="collage"
          style={{ height: `${height}px` }}
        >
          {testSets.length === 0 &&
            <div
              id="grey-screen"
              style={{ width: contentWidth }}
            >
            </div>
          }
          {this.state.submitModal &&
            <Fragment>
              {typeof this.state.secret !== 'string' || this.state.secret.length > 0 ?
              <div
                className={
                  this.state.slideUpSubmit ? "full-modal slideInUp" : "full-modal slideOutDown"
                }
                style={{ width: `${contentWidth}px` }}
              >
                <h1 className="text-align-center">Submit tests for review?</h1>
                <div>
                  <h3 className="underline text-align-center">This action cannot be undone.</h3>
                </div>
                <div className="vertical-btn-container">
                  <div
                    className="btn-div vertical-btn"
                    onClick={() => {
                      // // this.props.changePage('loadingScreenPage')
                      // if (this.state.secret !== "") {
                      //   this.setState({ cartModal: true, slideUpCart: true, slideUpSubmit: false })
                      // } else {
                      //   alert("There was an error getting to checkout, please try again later.")
                      //   this.getPaymentSecret(testSetsLength, queryString.parse(window.location.search).state)
                      // }
                      this.props.changePage('loadingScreenPage')
                    }}
                  >
                    <p>Yes</p>
                  </div>
                  <div
                    className="btn-div vertical-btn"
                    onClick={() => this.setState({ slideUpSubmit: false })}
                  >
                    <p>No</p>
                  </div>
                </div>
              </div> :
              <LoadingIcon2 />
              }
            </Fragment>
          }

          {this.state.cartModal &&
            <div
              className={
                this.state.slideUpCart ? "full-modal cart-modal slideInUp" : "full-modal cart-modal slideOutDown"
              }
              style={{ width: `${contentWidth}px` }}
            >
              <h1 className="text-align-center">Your Cart</h1>
              <div className="cart-content flex-column space-evenly">
                <div className="flex space-between">
                  <span>Basic Test</span>
                  <span>{`$${testPrice}`}</span>
                </div>
                {testSetsLength > 1 &&
                  <div className="flex space-between">
                    <span>Extras</span>
                    <span>{`(${testSetsLength - 1}) x $14`}</span>
                  </div>
                }
                <div style={{ height: '10px', borderBottom: '1px solid black' }}></div>
                <div className="flex space-between">
                  <span>Total</span>
                  <span><strong>{`$${extraTests * extraTestPrice + testPrice}`}</strong></span>
                  {/* <span><strong>{`$${testPrice}`}</strong></span> */}
                </div>
              </div>
              <Elements stripe={stripeThing()}>
                <CheckoutForm 
                  secret={this.state.secret}
                  handleLoading = {this.handleLoading}
                  changePage={this.props.changePage}
                />
              </Elements>
              <div className="vertical-btn-container">
                <div
                  className="btn-div vertical-btn"
                  onClick={() => this.setState({ slideUpCart: false })}
                >
                  <p>Cancel</p>
                </div>
              </div>
            </div>
          }

          {this.state.deleteModal &&
            <div
              className={
                this.state.slideUpDelete ? "full-modal slideInUp" : "full-modal slideOutDown"
              }
              style={{ width: `${contentWidth}px` }}
            >
              <h1 className="text-align-center">Delete this test entry?</h1>
              <div>
                <h3 className="underline text-align-center">This action cannot be undone.</h3>
              </div>
              <div className="vertical-btn-container">
                <div
                  className="btn-div vertical-btn"
                  onClick={() => {
                    this.props.deleteTestSet(this.state.testSetDropDown)
                    this.setState({ slideUpDelete: false, testSetDropDown: -1 })
                  }}
                >
                  <p>Yes</p>
                </div>
                <div
                  className="btn-div vertical-btn"
                  onClick={() => this.setState({ slideUpDelete: false, dropDown: {} })}
                >
                  <p>No</p>
                </div>
              </div>
            </div>
          }

          <div className="collage-header flex space-between align-center">
            <h1>Tests</h1>
            {(!this.props.testSets || this.props.testSets.length === 0) &&
            <div
              className={
                testSets.length < 1 ?
                  "circle flex justify-center align-center pulse z3" :
                  "circle flex justify-center align-center"
              }
            >
              <img
                src={plus}
                alt="add"
                onClick={() => {
                  this.props.storeTestSetAndImgIndex(testSets.length, 0)
                  this.props.changePage('bodyPartPage')
                }}
              />
            </div>
            }
          </div>
          <main className="photo-set-container">
            {testSets && testSets.length > 0 ?
              <Fragment>
                <div className="photo-collection">
                  {testSets.map((testSet, i) => {
                    return (
                      <div className="test-set" key={i}>
                        <div className="test-set-header" onClick={() => this.setState({ testSetDropDown: i, dropDown: { [i]: !this.state.dropDown[i] } })}>
                          <div className="flex space-between">
                            <p className="margin-0">{'Test Set ' + (i + 1)}</p>
                            <div className="flex">
                              <p className="margin-0">{this.handleDate(testSet.time)}</p>
                              <Deck />
                            </div>
                          </div>
                          <h2 className="margin-0">{testSet.moleLocation}</h2>
                        </div>
                        <div className="three-photo-container">
                          {testSet.croppedImageDataURLS.map((photo, j) => {
                            return (
                              <img
                                key={j}
                                className="album-image"
                                src={photo}
                                alt=''
                              ></img>
                            )
                          })}
                        </div>
                        {(this.state.testSetDropDown === i) &&
                          <div
                            className={this.state.dropDown[i] ? 'test-set-options drop-down' : 'test-set-options shrink-up'}
                            style={{ height: `${contentWidth * 0.3333 - (0.66 * fontSize) + 3}px` }}
                          >
                            <div className="flex two-btn-container">
                              <div
                                className="width-50 background-gray btn-div"
                                onClick={() => {
                                  this.props.storeTestSetAndImgIndex(i, 0)
                                  this.props.storeIsEditing(true)
                                  this.props.changePage('testSetPage')
                                }}
                              >
                                <p>Edit</p>
                              </div>
                              <div
                                className="width-50 btn-div"
                                onClick={() => this.setState({ deleteModal: true, slideUpDelete: !this.state.slideUpDelete })}
                              >
                                <p>Delete</p>
                              </div>
                            </div>
                          </div>
                        }
                      </div>
                    )
                  })}
                </div>
              </Fragment>
              :
              <Fragment>
                <div className="photo-collection">
                  <div className="test-set">
                    <div className="test-set-header flex-column space-evenly">
                      <div className="flex space-between">
                        <p className="margin-0">Example Test Set</p>
                        <div className="flex">
                          <p className="margin-0">09:15</p>
                          <Deck />
                        </div>
                      </div>
                      <h2 className="margin-0">Left Arm</h2>
                    </div>
                    <div className="three-photo-container">
                      <img
                        key={1}
                        className="album-image"
                        src={GoodMole}
                        alt=''
                      ></img>
                      <img
                        key={2}
                        className="album-image"
                        src={GoodMole}
                        alt=''
                      ></img>
                      <img
                        key={3}
                        className="album-image"
                        src={GoodMole}
                        alt=''
                      ></img>
                    </div>
                  </div>
                </div>
              </Fragment>
            }
          </main>
          {testSets.length > 0 &&
            <div
              className="btn-div submit-btn"
              onClick={() => this.setState({ submitModal: true, slideUpSubmit: true })}
              style={btnStyle}
            >
              <p>Submit</p>
            </div>
          }
          {/*this.state.modal > -1 &&
          <Fragment>
            <div id="grey-screen"></div>
            <Modal index={this.state.modal} toggleModal={this.toggleModal.bind(this)}/>
          </Fragment>
        */}
        </div>
      ) :
        (<Fragment>
          <Loading />
        </Fragment>)

    );
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  croppedImageUrls: state.imageReducer.images.croppedImageUrls,
  croppedImageFiles: state.imageReducer.images.croppedImageFiles,
  testSets: state.imageReducer.testSets,
  secret: state.imageReducer.secret
})

export default connect(mapStateToProps, { storeTestSetAndImgIndex, storeIsEditing, deleteTestSet, storeSecret })(Collage)