export const storeCroppedImage = (croppedImageUrl, croppedImageFile, croppedImageDataURL) => dispatch => {
  dispatch({
    type: 'STORE_CROPPED_IMAGE',
    payload: {
      croppedImageUrl,
      croppedImageFile,
      croppedImageDataURL
    }
  })
}
