import React from "react";

function Check() {
  return (
    <svg
      className="check"
      xmlns="http://www.w3.org/2000/svg"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#000"
          d="M31.857 17.434a20.97 20.97 0 00.598-2.236c.278-1.372.42-2.785.42-4.198h-2c0 1.28-.128 2.559-.38 3.802-.872 4.311-3.228 8.184-6.664 10.985a18.945 18.945 0 01-3.309-3.406l-1.603 1.196c1.177 1.577 2.628 3.016 4.315 4.278l.601.449.6-.451a21.157 21.157 0 006.612-8.316c1.76 2.208 2.404 5.128 1.627 8.043-.835 3.134-3.412 5.593-6.575 6.313-7.297 1.661-13.604-5.618-10.107-13.025.99-2.096 2.885-3.659 5.11-4.307 2.421-.706 4.755-.535 6.824.47l.874-1.799a10.924 10.924 0 00-5.192-1.093c-4.939.166-9.28 3.695-10.338 8.524-1.752 8.002 5.278 14.996 13.29 13.178 4.1-.93 7.35-4.258 8.2-8.377.767-3.729-.339-7.413-2.903-10.03"
        ></path>
      </g>
    </svg>
  );
}

export default Check;