import React, { Fragment, Component } from 'react'
import {connect} from 'react-redux'
import manFront from './Images/ManFrontMargin.jpg'
import manBack from './Images/ManBackMarginHiFi.jpg'
import womanFront from './Images/WomanFrontMarginHiFi.jpg'
import womanBack from './Images/WomanBackMarginHiFi.jpg'
import Flip from './Flip.svg'
import loadImage from '../node_modules/blueimp-load-image/js'
import ReactCrop from 'react-image-crop'
import xCircle from './xCircleWhite.svg'
import BodyPart2 from './BodyPart2'
import { createTestSet } from './createTestSetAction'

class BodyPart extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gender: 'Male',
      front: true,
      cropHasBeenAdjusted: false,
      crop: {
        unit: '%',
        height: 20,
        width: 20,
        aspect: 1 / 1,
        x: 10,
        y: 10,
      },

      minWidth: null,
      maxWidth: null,
      isImageSelected: false,
      imageFile: [],
      imageUrl: [],
      croppedImageFiles: [],
      croppedImageUrls: [],
      croppedImageFile: null,
      croppedImageUrl: null,
      isImageCropped: false,

      loading: false,
      tooSmall: false,
      showCollage: false,
      orientationCounter: 0
    }
  }

  onClickAddImage = (event) => {
    this.props.storeImageIndex(null)
    this.onSelectImage(event)
  }

  onClickNext = () => {
    console.log('to do: handle click next')
  }

  setImage = () => {
    let imageSrc
    if ((this.props.patientData.sex && this.props.patientData.sex === 'Female') ||
    /* lower case 's' if from api, upper case if from patient form because api
       accepts capital and responds with lower case...
    */
    (this.props.patientData.Sex && this.props.patientData.Sex === 'Female')) {
      console.log('female')
      if (this.state.front) {
        imageSrc = womanFront
      } else {
        imageSrc= womanBack
      }
    } else {
      console.log('male')
      if (this.state.front) {
        imageSrc = manFront
      } else {
        imageSrc= manBack
      }
    }
    loadImage(imageSrc, (img) => {
      const blobPromise = new Promise((resolve, reject) => {
        if (img.height < 480 || img.width < 480) {
          // alert("Warning: Image should be at least 480 x 480 pixels")
          this.setState({ tooSmall: true })
        } else {
          this.setState({ tooSmall: false })
        }
        img.toBlob(blob => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = 'fileName';
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve([this.fileUrl, blob]);
        }, 'image/jpeg');
      });

      blobPromise
        .then((imageArr) => {
          this.setState({
            front: !this.state.front,
            isImageSelected: true,
            imageUrl: imageArr[0],
            imageFile: imageArr[1],
            isImageCropped: false,
            showCollage: false
          })
        })
    }, { orientation: true })
  }

  componentDidMount = () => {
    this.setState({gender: this.props.patientData.Sex})
    this.setImage()
  }

  onImageLoaded = image => {
    this.imageRef = image;
  }

  onCropChange = (crop, percentCrop) => {
    this.setState({ crop: percentCrop })
  }

  generateBodyPart = (x, y) => {
    const centerX = x + (this.state.crop.width / 2) // x and y coordinates correspond to upper left corner of selector
    const centerY = y + (this.state.crop.height / 2) // centerX and centerY correspond to center of selector
    
    // y coordinates of body parts
    const chinYLine = 27
    const torsoTopLine = 30
    const abdomenTopLine = 40
    const legTopLine = 50
    const footTopLine = 85
    const handTopLine = 52
    const handBottomLine = 60
    const armTopLine = 30

    // x coordinate of body parts
    const midLine = 50
    const headXLine = 5
    const outsideLegXLine = 15
    const insideHandXLine = 20
    const outsideFootXLine = 20
    let shoulderXLine
    if (this.state.gender === 'Female') {
      shoulderXLine = 7
    } else {
      shoulderXLine = 10
    }

    // Gaps between body parts
    if ((centerX < midLine + 6) && (centerX > midLine - 6) && (centerY < 100) && centerY > 80) {
      return '' // Between feet
    }
    if ((centerX < midLine + 4) && (centerX > midLine - 4) && (centerY <= 80) && centerY > 70) {
      return '' // Between legs
    }
    if (((centerX < (midLine - headXLine) || centerX > (midLine + headXLine)) && centerY > 0 && centerY < 27)) {
      return ''  // Right and left of head
    }
    if (((centerX < (midLine - 20) || centerX > (midLine + 20)) && centerY > 27 && centerY < 40)) {
      return ''  // Right and left of shoulders
    }

    if ((centerX < (midLine + headXLine) && centerX > (midLine - headXLine)) && (centerY > chinYLine && centerY < torsoTopLine)) {
      return 'neck'
    }
    if ((centerX < (midLine + headXLine) && centerX > (midLine - headXLine)) && (centerY > 0 && centerY < chinYLine)) {
      if (!this.state.front) {
        return 'face'
      } else {
        return 'head'
      }
    } else if (centerX < (midLine + shoulderXLine) && centerX > (midLine - shoulderXLine) && (centerY > torsoTopLine && centerY < abdomenTopLine)) {
      if (!this.state.front) {
        return 'torso'
      } else {
        return 'back'
      }
    } else if (centerX < (midLine + shoulderXLine) && centerX > (midLine - shoulderXLine) && (centerY > abdomenTopLine && centerY < legTopLine)) {
        if (!this.state.front) {
          return 'abdomen'
        } else {
          return 'lower back'
        }
    } if (centerY > footTopLine) {
        if (centerX < midLine && centerX > outsideFootXLine) {
          if (!this.state.front) {
            return 'right foot'
          } else {
            return 'left foot'
          }
        } else if (centerX > midLine && centerX < (100 - outsideFootXLine)) {
          if (!this.state.front) {
            return 'left foot'
          } else {
            return 'right foot'
          }
        }
    } else if ((centerY > legTopLine)) {
        if (centerX < midLine && centerX > 50 - outsideLegXLine) {
          if (!this.state.front) {
            return 'right leg'
          } else {
            return 'left leg'
          }
        } else if (centerX > midLine && centerX < (50 + outsideLegXLine)) {
          if (!this.state.front) {
            return 'left leg'
          } else {
            return 'right leg'
          }
        }
     } if (centerY > handTopLine && centerY < handBottomLine) {
        if (centerX < (midLine - insideHandXLine)) {
          if (!this.state.front) {
            return 'right hand'
          } else {
            return 'left hand'
          }
        } else if (centerX > (midLine + insideHandXLine)) {
          if (!this.state.front) {
            return 'left hand'
          } else {
            return 'right hand'
          }
        }
     } if (centerY > armTopLine && centerY < handTopLine) {
        if (centerX < (midLine - shoulderXLine)) {
          if (!this.state.front) {
            return 'right arm'
          } else {
            return 'left arm'
          }
       } else if (centerX > (midLine + shoulderXLine)) {
         if (!this.state.front) {
           return 'left arm'
         } else {
           return 'right arm'
         }
       } else {
         return ''
       }
     } else {
       return ''
    }
  }

  adjustCoordinatesFromZoom = (centerX, centerY) => {
    const { crop, percentCrop, sX, sY } = this.state
    const canvas = document.getElementsByClassName('ReactCrop__image')[0]

    const sXPercent = sX / canvas.width * 100
    const sYPercent = sY / canvas.height * 100
    
    const selectionToFullRatio = 100 / canvas.width

    const newCenterX = sXPercent + (selectionToFullRatio * centerX)
    const newCenterY = sYPercent + (selectionToFullRatio * centerY)

    const newSelectionCornerX = newCenterX - (percentCrop.width / 2)
    const newSelectionCornerY = newCenterY - (percentCrop.height / 2)
    
    const handleMargins = (coord, selector) => {
      if (coord < 0) {
        return 0
      } else if (coord + selector > 100) {
        return 100 - selector
      } else {
        return coord
      }
    }

    const x = handleMargins(newSelectionCornerX, percentCrop.width)
    const y = handleMargins(newSelectionCornerY, percentCrop.height)

    this.setState({
      moleCoordinates: [newCenterX, newCenterY],
      crop: {
        ...crop,
        unit: '%',
        x,
        y,
        width: this.state.percentCrop.width,
        height: this.state.percentCrop.height
      }
    })
  }

  onCropComplete = (crop, percentCrop) => {
    const canvas = document.getElementsByClassName('ReactCrop__image')[0]
    const trueRatio = canvas.height / canvas.width
    
    const width = 100
    const height = 100 * trueRatio

    const a = (width - crop.width) / 2 // distance from edge of crop area to edge of selector
    const b = (height - crop.height) / 2

    const x = (crop.x - a) // > 0 ? crop.x - a : 0 // x and y coordinates of new crop area (upper left corner)
    const y = (crop.y - b) // > 0 ? crop.y - b : 0

    const center = [
      percentCrop.x + (percentCrop.width / 2),
      percentCrop.y + (percentCrop.height / 2),
    ]

    const adjustedCrop = { ...crop, x, y, width, height }
    this.setState({sX: x, sY: y, sW: width, sH: height, percentCrop, pixelCrop: crop, center, trueRatio})
    this.makeClientCrop(adjustedCrop);
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      const newCroppedImageUrl = croppedImage[0]
      const newCroppedImageFile = croppedImage[1]

      this.setState({
        croppedImageUrl: newCroppedImageUrl,
        croppedImageFile: newCroppedImageFile
      });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const maxWidth = 960
    const shrinkRatio = maxWidth / image.naturalWidth
    
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    if (image.naturalWidth > maxWidth) {
      canvas.width = Math.floor(crop.width * scaleX * shrinkRatio);
      canvas.height = Math.floor(crop.height * scaleY * shrinkRatio);
    } else {
      canvas.width = Math.floor(crop.width * scaleX);
      canvas.height = Math.floor(crop.height * scaleY);
    }
    const ctx = canvas.getContext('2d')

    /* Math for guaranteeing minimum size of 480 x 480 */
    let minWidth

    if (image.naturalWidth > maxWidth) {
      minWidth =  480 / scaleX / shrinkRatio
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX * shrinkRatio,
        crop.height * scaleY * shrinkRatio,
      );
  
    } else {
      minWidth =  480 / scaleX
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
    }

    this.setState({ minWidth })

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
        }
        blob.name = fileName;
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve([this.fileUrl, blob]);
      }, 'image/jpeg');
    });
  }

  onClickDone() {
    if (!this.state.isImageCropped) { // Zoom into area
      this.setState({
        cropHasBeenAdjusted: false,
        isImageCropped: true,
      })
    } else { // Save location and coordinates via redux
      const time = Date.now()
      const x = this.state.crop.x
      const y = this.state.crop.y
      // const centerX = x + (this.state.crop.width / 2) x and y coordinates correspond to upper left corner of selector
      // const centerY = y + (this.state.crop.width / 2) centerX and centerY correspond to center of selector
      const moleLocation = this.generateBodyPart(x, y)
      // const moleCoordinates = [centerX, centerY]
      const front = !this.state.front // front or back?
      this.props.createTestSet(time, moleLocation, this.state.moleCoordinates, front)
      this.props.changePage('testSetPage')
    }
  }

  onClickCancelCrop = (event) => {
    event.stopPropagation()
    this.state.isImageCropped
    ? this.setState({
      croppedImageUrl: null,
      croppedImageFile: null,
      isImageCropped: false
    })
    : this.setState({
      cropHasBeenAdjusted: false,
      croppedImageUrl: null,
      croppedImageFile: null,
      imageUrl: [],
      imageFile: [],
      isImageSelected: false,
      crop: {
        unit: '%',
        height: 100,
        width: 100,
        aspect: 1 / 1
      }
    })
  }

  render() {
    window.addEventListener('resize', () => {
      this.setState({orientationCounter: this.state.orientationCounter + 1})
    })
    window.addEventListener('orientationchange', () => {
      this.setState({orientationCounter: this.state.orientationCounter + 1}) // trigger new render
    })
    const cropper = !this.state.isImageCropped ?
      <div className="cropper-container">
        <div className="image-container">
          <ReactCrop
            className='crop-image'
            src={this.state.imageUrl}
            crop={this.state.crop}
            ruleOfThirds={false}
            locked={true}
            onImageLoaded={this.onImageLoaded}
            onComplete={this.onCropComplete}
            onChange={this.onCropChange}
            minWidth={this.state.minWidth}
            minHeight={this.state.minWidth}
            maxWidth={this.state.maxWidth}
            maxHeight={this.state.maxHeight}
            keepSelection={true}
          />
        </div>
      </div>
    : <BodyPart2
        croppedImageUrl={this.state.croppedImageUrl}
        adjustCoordinatesFromZoom={this.adjustCoordinatesFromZoom}
        trueRatio={this.state.trueRatio}
      />

    const cropperContainer = this.state.isImageSelected && (
      <Fragment>
        {cropper}
      </Fragment>
    )

    const selectedPart = this.generateBodyPart(this.state.crop.x, this.state.crop.y)

    return (
      <Fragment>
        {(window.orientation === 90 || window.orientation === -90) ?
        <div
          className="flipped">
          <h2>Please use device in vertical view</h2>
        </div>
        :
        <div
          className="body-part-container"
          style={{ minHeight: `${window.innerHeight}px` }}
        >
          <div className="g">
            <div className="flex space-between">
              <div className="x-div flex justify-start align-start">
                <img className="svg-48" src={xCircle} alt="cancel" onClick={() => this.props.changePage('collagePage')} />
              </div>
              <p className="white margin-0 font-1-5-em text-align-center width-50">Where is the mole?</p>
              <div className="flip-div border-white flex-column align-center" onClick={() => this.setImage()}>
                <img src={Flip} alt="flip" />
                <p className="margin-0 align-text-center white">{this.state.front ? 'Flip to front' : 'Flip to back'}</p>
              </div>
            </div>
            <div className="k flex justify-center align-center">
              <div className="l flex align-end justify-end">
                <p className="white">{this.state.front ? 'L' : 'R'}</p>
              </div>
              {cropperContainer}
              <div className="r flex align-end justify-start">
                <p className="white">{this.state.front ? 'R' : 'L'}</p>
              </div>
            </div>
          </div>
          <div className="i flex justify-center">
            <p className="white margin-0">{selectedPart}</p>
          </div>
          <div className="j">
            {this.state.isImageCropped &&
              <div className="two-btn-container">
                <div
                  className="btn-div background-white width-50"
                  onClick={this.onClickCancelCrop}
                >
                  <p className="black">Back</p>
                </div>
                { selectedPart.length > 0 &&
                  <div
                    className="btn-div background-gray width-50"
                    onClick={this.onClickDone.bind(this)}
                  >
                    <p className="white">Select</p>
                  </div>
                }
              </div>
            }
            {(selectedPart.length > 0 && !this.state.isImageCropped) &&
              <div className="btn-div" onClick={this.onClickDone.bind(this)}>
                <p className="white">Next</p>
              </div>
            }
          </div>
        </div>
        }
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  testSets: state.imageReducer.testSets,
  patientData: state.imageReducer.patientData
})

export default connect(mapStateToProps, { createTestSet })(BodyPart)