import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux'
import store from './store'
import App from './App';

const rootElement = document.getElementById('root');

const routing = (
  <Provider store={store}>
    <App />
  </Provider>
)

ReactDOM.render(routing, rootElement);
