import React, { Fragment, Component } from 'react'
import {connect} from 'react-redux'
import loadImage from 'blueimp-load-image'
import { storeOriginalImage } from './storeOriginalImageAction'
import plus from './Plus.svg'
import { storeTestSetAndImgIndex } from './storeTestSetAndImgIndexAction'

class Upload extends Component {
  constructor(props) {
    super(props);

    this.state = {
    }
  }

  onClickUpload = () => {
    this.props.storeTestSetAndImgIndex(this.props.testSetIndex, this.props.imgIndex)
  }

  onSelectImage = event => {

    loadImage( event.target.files[0], (img) => {
      const blobPromise = new Promise((resolve, reject) => {
        if (img.height < 480 || img.width < 480) {
          // alert("Warning: Image should be at least 480 x 480 pixels")
          this.setState({ tooSmall: true })
        } else {
          this.setState({ tooSmall: false })
        }
        img.toBlob(blob => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = 'fileName';
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve([this.fileUrl, blob]);
        }, 'image/jpeg');
      });

      blobPromise
        .then((imageArr) => {
          this.setState({
            isImageSelected: true,
            imageUrl: imageArr[0],
            imageFile: imageArr[1],
          })
        })
    }, { orientation: true })
  }

  componentDidUpdate() {
    if (this.state.imageFile && this.state.imageUrl) {
      this.props.storeOriginalImage(this.state.imageFile, this.state.imageUrl)
      this.props.changePage('imageUploadPage')
    }
  }

  render() {
    let url
    if (this.props.testSets) {
      url = this.props.testSets[this.props.testSetIndex].croppedImageDataURLS[this.props.imgIndex]
    }
    return(
      <Fragment>
        <label onClick={this.onClickUpload} id="upload-image-label" className="upload-image-label" htmlFor="AddImageInput">
          <div id={this.props.imgIndex === 1 ? "no-sides" : null} className="carousel-image">
            { url ? <img className="mole-img" src={url} alt="test" /> :
              <Fragment>
                {this.props.imgIndex > 0 ?
                <h3>Add another image of the same mole</h3> :
                <h3>Add Image</h3>
                }
                <img className={"carousel-plus"} src={plus} alt="plus" />
              </Fragment>
            }
          </div>
        </label>
        <input
          hidden
          id="AddImageInput"
          type="file"
          accept="image/*"
          onChange={this.onSelectImage}
          onClick={(event)=> {
            event.target.value = null
          }}
        />
        <input
          hidden
          id="replace-image"
          type="file"
          accept="image/*"
          onChange={this.onSelectImage}
          onClick={(event)=> {
            event.target.value = null
          }}
        />
    </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  testSetIndex: state.imageReducer.testSetIndex,
  testSets: state.imageReducer.testSets
})

export default connect(mapStateToProps, {storeOriginalImage, storeTestSetAndImgIndex})(Upload)