import React from "react";

function Deck() {
  return (
    <svg
      style={{marginTop: '-5px', height: '2em'}}
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      width="48"
      height="48"
      viewBox="0 0 48 48"
    >
      <defs>
        <path id="a" d="M0 33L25 33 25 0 0 0z"></path>
      </defs>
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g transform="translate(12 8)">
          <path fill="#000" d="M11 12a3 3 0 11-6 0 3 3 0 016 0"></path>
          <mask id="b" fill="#fff">
            <use xlinkHref="#a"></use>
          </mask>
          <path
            fill="#000"
            d="M2 22h12V2H2v20zM21 7V3h-4v2h2v22H5v-3h11V0H0v24h3v5h18V9h2v22H9v-1H7v3h18V7h-4z"
            mask="url(#b)"
          ></path>
        </g>
      </g>
    </svg>
  );
}

export default Deck;