import React, { Fragment } from 'react'
import {connect} from 'react-redux'
// import Deck from './Deck.js'
import Dm from './Dm.js'
import Check from './Check.js'
import GoodMole from './Images/GoodMole.jpg'
import DarkMole from './Images/DarkMole.jpg'
import BlurryMole from './Images/BlurryMole.jpg'
import BackgroundMole from './Images/BackgroundMole.jpg'
import check from './Images/check.svg'
import warning from './Images/Warning.svg'
// import ImageUpload from './ImageUpload'

function Checklist(props) {
  return (
    <div
      id="checklist-container"
      className="container"
      // style={{ height: `${window.innerHeight}px`}}
      style={{ minHeight: `${window.innerHeight}px` }}
    >
      {!props.pages.cropperPage &&
      <Fragment>
        <header id="check-list-header" className="flex space-between align-center">
          <div className="width-1-3 flex justify-center align-center height-100">
            <Dm />
          </div>
          <div className="height-4em flex-column align-start space-between width-2-3">
            <p className="margin-0">Lazarus</p>
            <p className="margin-0">Clinical Decision Support</p>
            <p className="bold margin-0">Dermatology</p>
          </div>
        </header>
        <h1>Before you begin</h1>
        <div id="instructions-container" className="flex-column justify-center">
          <div id="instructions">
            <h2 className="bold margin-top-1em">Instructions</h2>
            <h3 className="line-height">Take photos of any concerning moles. You can use previously taken photos</h3>
          </div>
        </div>
        <div>
          <img className="example-mole-img" src={GoodMole} alt="good mole" />
          <div className="flex">
            <img src={check} alt="check-mark"/>
            <p>Example of a good photo</p>
          </div>
        </div>
        <div className="flex-column space-evenly checklist">
          <h2 id="checklist-h2" className="bold">Image Checklist</h2>
          <div className="checks flex-column space-evenly">
            <div className="check-item flex align-center">
              <div className="check-div"><Check /></div><p>Skin is well-lit</p>
            </div>
            <div className="check-item flex">
              <div className="check-div"><Check /></div><p>Moles should be centered and clear</p>
            </div>
            <div className="check-item flex">
              <div className="check-div"><Check /></div><p>Image should show skin only</p>
            </div>
          </div>
        </div>
        <h2>AVOID THE FOLLOWING SITUATIONS</h2>
        <div>
          <img className="example-mole-img" src={DarkMole} alt="bad lighting" />
          <div className="flex warning-div">
            <img src={warning} alt="warning" />
            <p>Photo is not well-lit</p>
          </div>
        </div>
        <div>
          <img className="example-mole-img" src={BlurryMole} alt="bad lighting" />
          <div className="flex warning-div">
            <img src={warning} alt="warning" />
            <p>Photo is not in focus</p>
          </div>
        </div>
        <div>
          <img className="example-mole-img" src={BackgroundMole} alt="bad lighting" />
          <div className="flex warning-div">
            <img src={warning} alt="warning" />
            <p>Photo contains background</p>
          </div>
        </div>
      </Fragment>
      }
      <div className="flex-column flex-end">
        <div className="btn-div margin-1em" onClick={() => props.changePage('collagePage')}>
          <p>Start</p>
          {/* <ImageUpload togglePage={props.togglePage} /> */}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientName: state.imageReducer.patientName
})

export default connect(mapStateToProps)(Checklist)