import React, {Component, Fragment} from 'react'
import {connect} from 'react-redux'
import LazarusLogo from './LazarusLogo'
import Dm from './Dm.js'
// import Pricing from './Pricing'
import LoadingIcon from './LoadingIcon'
import Profile from './Images/Profile.svg'
import queryString from 'query-string'

class Instructions extends Component {
  constructor(props) {
    super(props)
    this.state = {
      pricingModal: false,
      slideIn: false, // modal animation
    }
  }
  // let height = window.innerHeight
  // let width = window.innerWidth
  // const resize = () => {
  //   if (window.innerWidth > window.innerHeight || window.orientation === 90|| window.orientation === -90) {
  //     height = window.innerWidth
  //     width = window.innerHeight
  //   }
  //   document.getElementById('root').style.width = width
  //   document.getElementById('root').style.height = height
  // }
  // window.addEventListener('resize', () => {
  //   resize()
  // })
  // resize()

  getPhysicianInfo = () => {
    async function postData(url = '', data = {}) {
      // Default options are marked with *
      const response = await fetch(url, {
        method: 'POST', // *GET, POST, PUT, DELETE, etc.
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
          'Content-Type': 'application/json'
          // 'Content-Type': 'application/x-www-form-urlencoded',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
        body: JSON.stringify(data) // body data type must match "Content-Type" header
      }).catch((error) => {
        console.log(error)
      })
      return response.json() // parses JSON response into native JavaScript objects
    }

    const physicianId = queryString.parse(window.location.search).state
    const patientId = queryString.parse(window.location.search).patient
    const body = {
      state: physicianId
    }

    if (physicianId && patientId) {
      postData('https://us-central1-lazarus-cds.cloudfunctions.net/checkPhysician', body)
        .then((data) => {
          if (data.status === "Success") {
            this.setState({ physicianInfo: data.info })
          } else {
            this.props.changePage('errorPage')
          }
        })
        .catch((error) => {
          console.log('Error: ', error)
          this.props.changePage('errorPage')
        })
          
    }
  }

  componentDidMount() {
    this.getPhysicianInfo()
  }

  toggleSlideIn = () => {
    this.setState({slideIn: !this.state.slideIn})
  }

  render() {
    const {physicianInfo} = this.state
    return (
      <div
        className="container1"
        // style={{ height: `${height}px`, width: `${width}px` }}
        style={{ height: `${window.innerHeight}px` }}
      >
        <header className="flex-column space-around page-1-header">
          <LazarusLogo />
          <div className="middle-row flex space-between align-center">
            <Dm />
            <div id="mobitest">
              <p><strong>M-PROC</strong></p>
            </div>
          </div>
          <div className="height-4em flex-column align-start space-between">
            <p className="margin-0">Lazarus</p>
            <p className="margin-0">Clinical Decision Support</p>
            <p className="bold margin-0">Dermatology</p>
            {/* <p>{`Height: ${window.innerHeight}, Orientation: ${window.orientation}`}</p> */}
          </div>
        </header>
        <main className="flex-column space-between">
          <div className="testing-for-div selected-div">
            <div>
              <p>Testing for</p>
              <img src={Profile} alt="user" />
            </div>
            <p>{ this.props.patientName && this.props.patientName.length > 30 ? this.props.patientName.slice(0, 30) + '...' : this.props.patientName}</p>
          </div>
          {physicianInfo ?
          <Fragment>
            <div className="test-ordered-div">
              <p><small>Test ordered by</small></p>
              <p><strong>{physicianInfo.Name && physicianInfo.Name}</strong></p>
            </div>
            {/*<div className="physician-contact-div">
              <p><small>Contact</small></p>
              <p>{physicianInfo.Number && physicianInfo.Number}</p>
              <p>{physicianInfo.ContactEmail && physicianInfo.ContactEmail}</p>
            </div>
            */}
          </Fragment> :
          <LoadingIcon />
          }
          <footer>
            <div className="btn-div" onClick={() => this.props.changePage('checklistPage')}>
              <p>Next</p>
            </div>
          </footer>
        </main>
        {/*this.state.pricingModal &&
          <Pricing
            slideIn={this.state.slideIn}
            toggleSlideIn={this.toggleSlideIn} 
            changePage={this.props.changePage}
          />
        */}
      </div>
    );
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientName: state.imageReducer.patientName
})

export default connect(mapStateToProps)(Instructions)