import React, { Fragment, Component } from 'react'
import {connect} from 'react-redux'
import { submitTestSets } from './submitTestSetsAction.js'
import block from './Block.svg'

class LoadingScreen extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoadingBarFinished: false
    }
  }

  componentDidMount() {
    this.props.submitTestSets(this.props.testSets, this.props.storage, this.props.firestore, this.props.patientID, this.props.paymentIntent)
    setTimeout(() => {
      this.setState({isLoadingBarFinished: true})
    }, 7000);
  }

  componentWillReceiveProps(props) {
    if (props.batchUploadResponse) {
      localStorage.removeItem('storageState');
      this.props.changePage('resultPage')
    }
  }

  render() {
    return(
      <Fragment>
        <div
          className="loading-screen-page flex justify-center align-center"
          // style={{ height: `${window.innerHeight}px`}}
          style={{ minHeight: `${window.innerHeight}px` }}
        >
          <div className="loading-screen-container">
            <img className="block" src={block} alt="block" />
            <div className="loading-bar-container">
              <div className="loading-bar">
              </div>
            </div>
            <p>Secure upload in progress. Please do not close this tab until upload is complete</p>
          </div>
        </div>
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  testSetIndex: state.imageReducer.testSetIndex,
  testSets: state.imageReducer.testSets,
  batchUploadResponse: state.imageReducer.batchUploadResponse,
  patientID: state.imageReducer.patientID,
  paymentIntent: state.imageReducer.paymentIntent
})

export default connect(mapStateToProps, { submitTestSets })(LoadingScreen)