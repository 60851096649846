import queryString from 'query-string';

export const submitTestSets = (testSets, storage, firestore, patientID, paymentIntent) => dispatch => {
  const uuidv4 = require('uuid/v4')

  let newDate = new Date()
  let date = newDate.getDate();
  let month = newDate.getMonth() + 1;
  let year = newDate.getFullYear();
  let hour = newDate.getHours();
  let min = newDate.getMinutes();
  let sec = newDate.getSeconds();

  let testArray = []
  let downloadURLArray = []
  let batchToUpload = []

  // function dataURItoBlob(dataURI) {
  //   // convert base64/URLEncoded data component to raw binary data held in a string
  //   var byteString;
  //   if (dataURI.split(',')[0].indexOf('base64') >= 0)
  //       byteString = atob(dataURI.split(',')[1]);
  //   else
  //       byteString = unescape(dataURI.split(',')[1]);

  //   // separate out the mime component
  //   var mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0];

  //   // write the bytes of the string to a typed array
  //   var ia = new Uint8Array(byteString.length);
  //   for (var i = 0; i < byteString.length; i++) {
  //       ia[i] = byteString.charCodeAt(i);
  //   }

  //   return new Blob([ia], {type:mimeString});
  // }

  testSets.forEach((testSet, index) => {
    testSet.croppedImageFiles.forEach(image => {
      // for (let i = 0; i < 3; i++) {
      // var image = testSet["croppedImageFiles"][i]
      // const blob = dataURItoBlob(image)

      // const blobPromise = new Promise((resolve, reject) => {
      //   const blob = dataURItoBlob(image)
      //   resolve(blob)
      //   reject('error')
      // })

      // blobPromise.then(blob => {
      //   console.log('blob success')
      //   testArray.push(blob)
      // })

      testArray.push(image)
    })
    let id = uuidv4()
    let dataToAdd = {
      Id: id,
      Front: testSet["front"],
      MoleCoordinates: testSet["moleCoordinates"],
      MoleLocation: testSet["moleLocation"],
      PaymentIntent: "Demo",
      Tests: [
        {
          data: {
            DateTaken: `${year}/${month < 10 ? `0${month}` : `${month}`}/${date < 10 ? `0${date}` : `${date}`} ${hour < 10 ? `0${hour}` : `${hour}`}:${min < 10 ? `0${min}` : `${min}`}:${sec < 10 ? `0${sec}` : `${sec}`}`
          },
          id: id + "-0"
        },
        {
          data: {
            DateTaken: `${year}/${month < 10 ? `0${month}` : `${month}`}/${date < 10 ? `0${date}` : `${date}`} ${hour < 10 ? `0${hour}` : `${hour}`}:${min < 10 ? `0${min}` : `${min}`}:${sec < 10 ? `0${sec}` : `${sec}`}`
          },
          id: id + "-1"
        },
        {
          data: {
            DateTaken: `${year}/${month < 10 ? `0${month}` : `${month}`}/${date < 10 ? `0${date}` : `${date}`} ${hour < 10 ? `0${hour}` : `${hour}`}:${min < 10 ? `0${min}` : `${min}`}:${sec < 10 ? `0${sec}` : `${sec}`}`
          },
          id: id + "-2"
        }
      ],
    }

    if (testSet["notes"]) {
      dataToAdd["Notes"] = testSet["notes"]
    }

    batchToUpload.push(dataToAdd)
  })
  // console.log(testArray)
  // console.log(batchToUpload)

  // let self = this
  // const uuidv4 = require('uuid/v4');
  const storageRef = storage.ref("Tests")
  testArray.forEach((testSet, index) => {  // testSet here refers to an individual test image data url, rename;
    let testname = uuidv4()

    storageRef.child(testname).put(testSet).then((snapshot) =>
      snapshot.ref.getDownloadURL().then((downloadURL) => {

        let testSetNum = Math.floor(index / 3)
        let testNum = index % 3

        batchToUpload[testSetNum]["Tests"][testNum]["data"]["ImageRef"] = downloadURL
        downloadURLArray.push(downloadURL)

        let testSetName = uuidv4()

        if (downloadURLArray.length === testArray.length) {
          firestore.collection("PatientTests").doc(`${testSetName}`).set({
            Patient: patientID,
            Source: 'Patient',
            TestSets: batchToUpload,
            Time: `${year}/${month < 10 ? `0${month}` : `${month}`}/${date < 10 ? `0${date}` : `${date}`} ${hour < 10 ? `0${hour}` : `${hour}`}:${min < 10 ? `0${min}` : `${min}`}:${sec < 10 ? `0${sec}` : `${sec}`}`
          }).then(() => {
            (async () => {
              //let test = queryString.parse(window.location.search).batchId

              let dataToSend = JSON.stringify({
                patient: (queryString.parse(window.location.search).patient) ? (queryString.parse(window.location.search).patient) : patientID,
                patientID: "a",
                physician: queryString.parse(window.location.search).state,
                id: testSetName,
                ids: ['N/a'],
                source: "Patient"
              })
              const rawResponse = await fetch('https://us-central1-raw-compute.cloudfunctions.net/dermato-1', {
                method: 'POST',
                body: dataToSend
              });
              const content = await rawResponse.json();

              if (content["message"] === "Success!") {
                dispatch({
                  type: 'SUBMIT_TEST_SETS',
                  payload: 'success'
                })
              } else {
                dispatch({
                  type: 'SUBMIT_TEST_SETS',
                  payload: 'failure'
                })
              }
            })()
          })
            // .then((returnValue) => {
            //   console.log('return value', returnValue)
            //   dispatch({
            //     type: 'SUBMIT_TEST_SETS',
            //     payload: 'success'
            //   })
            // })
          .catch(() => {
            dispatch({
              type: 'SUBMIT_TEST_SETS',
              payload: 'failure'
            })
          })
        }
      })
    )
  })
}


