import React from 'react'
import {connect} from 'react-redux'
import check from './Images/check.svg'

function PricingModal(props) {
  
  return (
    <div id="pricing-modal" className={props.slideIn ? "full-modal slideInUp" : "full-modal slideOutDown"}>
      <h1>Pricing</h1>
      <div className="pricing-items">
        <div className="flex-column space-between">
          <p>Basic Test</p>
          <h1>$50</h1>
          <div className="flex align-items-center">
            <img src={check} alt="check" />
            <span>3 images of one area</span>
          </div>
        </div>
        {/*
        <div className="flex-column space-between">
          <p>Extras</p>
          <h1>$14/test</h1>
          <div className="flex align-items-center">
            <img src={check} alt="check" />
            <span>Additional tests</span>
          </div>
        </div>
        */}
      </div>
      <div>
        <p>
          You will not be charged until the end of the test
        </p>
      </div>
      <div
        className="btn-div"
        onClick={() => {
          props.setAppState({slideIn: false})
          setTimeout(() => {
            props.changePage('checklistPage')
          }, 1);
        }}
        >
        <p>Next</p>
      </div>
    </div>
  );
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
})

export default connect(mapStateToProps)(PricingModal)