import React from "react";

function Dm() {
  return (
    <svg
      className="dm"
      xmlns="http://www.w3.org/2000/svg"
      width="142"
      height="93"
      viewBox="0 0 142 93"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000">
          <path d="M114 0L114 7.1294557 134.892451 7.1294557 134.892451 85.8708838 114 85.8708838 114 93 142 93 142 0z"></path>
          <path d="M0 0L0 93 28 93 28 85.8708838 7.10788758 85.8708838 7.10788758 7.1294557 28 7.1294557 28 0z"></path>
          <g transform="translate(22 21)">
            <path d="M25.562 32.101c2.05-2.436 3.077-5.96 3.077-10.575 0-4.614-1.027-8.139-3.077-10.576-2.052-2.435-5.108-3.655-9.167-3.655H9.27v28.46h7.126c4.06 0 7.115-1.217 9.167-3.654m6.834-26.358c3.67 3.71 5.507 8.97 5.507 15.783 0 6.814-1.837 12.074-5.507 15.782-3.671 3.71-8.854 5.563-15.547 5.563H.329V.18h16.52c6.693 0 11.876 1.854 15.547 5.562"></path>
            <path d="M94.619 12.923c2.18 2.027 3.27 4.938 3.27 8.732v21.216h-8.874V23.4c0-2.241-.497-3.89-1.49-4.948-.994-1.056-2.354-1.584-4.081-1.584-2.073 0-3.78.755-5.118 2.263-1.339 1.51-2.008 3.622-2.008 6.34V42.87h-8.875V23.4c0-2.241-.497-3.89-1.49-4.948-.994-1.056-2.376-1.584-4.146-1.584-1.943 0-3.618.743-5.02 2.231-1.405 1.488-2.106 3.698-2.106 6.63v17.14h-8.875V10.53h7.968l.26 5.757c1.078-2.113 2.558-3.709 4.437-4.787 1.878-1.078 3.984-1.617 6.316-1.617 2.418 0 4.567.561 6.446 1.681 1.878 1.122 3.249 2.74 4.113 4.852 1.037-2.113 2.548-3.73 4.535-4.852 1.986-1.12 4.146-1.681 6.478-1.681 3.325 0 6.078 1.014 8.26 3.04"></path>
          </g>
        </g>
      </g>
    </svg>
  );
}

export default Dm;