import React from "react";

function Error() {
  return (
    <div className="error-page" style={{minHeight: `${window.innerHeight}px`}}>
      <div className="full-modal">
        <div>
          <div style={{borderBottom: '1px solid black', marginBottom: '16px'}}>
            <h1 style={{ textAlign: 'center' }}>Error</h1>
          </div>
        </div>
        <div>
          <h3>Oops! It looks like your link is incomplete</h3>
          <h3>Please follow the link provided by your physician</h3>
        </div>
      </div>
    </div>
  );
}

export default Error;