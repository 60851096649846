import React, { Fragment, Component } from 'react'
import {connect} from 'react-redux'
import Dm from './Dm.js'
import LoadingIcon from './LoadingIcon'
import {storePatientData} from './storePatientDataAction.js'
import { Redirect } from "react-router-dom";
import queryString from 'query-string';

class PatientForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      response: {
        'First Name': null,
        'Last Name': null,
        'Email': null,
        'Phone Number': null,
        'Sex': 'Male'
      }
    }
  }

  componentDidMount() {
    let height = window.innerHeight
    let width = window.innerWidth
    this.setState({height, width})
    const resize = () => {
      height = window.innerHeight
      width = window.innerWidth
      
      this.setState({width, height})
    }
    window.addEventListener('resize', resize)
    resize()
  }

  onChange = (event) => {
    event.preventDefault()
    this.setState({
      ...this.state,
      response: {...this.state.response, [event.target.name]: event.target.value}
    })
  }

  isFormValid = () => {
    const { response } = this.state
    const responseKeys = Object.keys(response)
    const areAnyAnswersMissing = responseKeys.some(key => !this.state.response[key])
    const isBirthdateValid =
      parseInt(response.day) >= 1 && parseInt(response.day) <= 31 &&
      parseInt(response.month) >= 1 && parseInt(response.month) <= 12 &&
      parseInt(response.year) > 1900
    if (areAnyAnswersMissing) {
      this.setState({ error: 'Please complete the form'})
      return false
    } else if (!isBirthdateValid) {
      this.setState({ error: 'Please enter birthday in correct format (e.g. 12/31/1999)'})
      return false
    } else {
      this.setState({ error: null })
      return true
    }
  }

  onSubmit = () => {
    if (this.isFormValid()) {
      const response = {...this.state.response, Birthdate: this.handleBirthday()}
      delete response.day
      delete response.month
      delete response.year
      this.setState({isLoading: true})
      async function postData(url = '', data = {}) {
        // Default options are marked with *
        const response = await fetch(url, {
          method: 'POST', // *GET, POST, PUT, DELETE, etc.
          cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
          credentials: 'same-origin', // include, *same-origin, omit
          headers: {
            'Content-Type': 'application/json'
            // 'Content-Type': 'application/x-www-form-urlencoded',
          },
          redirect: 'follow', // manual, *follow, error
          referrerPolicy: 'no-referrer', // no-referrer, *no-referrer-when-downgrade, origin, origin-when-cross-origin, same-origin, strict-origin, strict-origin-when-cross-origin, unsafe-url
          body: JSON.stringify(data) // body data type must match "Content-Type" header
        }).catch((error) => {
          console.log(error)
        })
        return response.json() // parses JSON response into native JavaScript objects
      }
      
      postData('https://us-central1-lazarus-cds.cloudfunctions.net/logPatient', response)
        .then((data) => {
          const patientName = this.state.response['First Name'] + ' ' + this.state.response['Last Name']
          if (data.id) {
            const state = queryString.parse(window.location.search).state
            const url = '?state=' + state + '&patient=' + data.id
            this.setState({
              redirect: url
            })
          }
          this.props.storePatientData(this.state.response, patientName, data.id)
          this.setState({isLoading: false})
          this.props.changePage('instructionsPage')
        })
        .catch(() => alert('Error. Please try again'))
    }
  }

  handleBirthday = () => {
    let birthday = ''
    birthday+= (this.state.response.year + '-')
    birthday+= (this.state.response.month + '-')
    birthday+= (this.state.response.day)
    return birthday
  }

  giveRedBorder = (field) => {
    if (this.state.error && !this.state.response[field]) {
      return {
        borderColor: 'red'
      }
    } else {
      return
    }
  }

  giveRedBorderBirthday = (field) => {
    if (this.state.error && (this.state.error.includes('birthday') || !this.state.response[field])) {
      return {
        borderColor: 'red'
      }
    } else {
      return
    }
  }

  render() {
    return(
      <div
        className="patient-form-page"
        style={{ minHeight: `${window.innerHeight}px` }}
      >
        {this.state.redirect &&
          <Redirect to={this.state.redirect} />
        }
        {this.state.isLoading ?
        <LoadingIcon /> :
        <Fragment>
          <header id="check-list-header" className="flex space-between align-center">
            <div className="width-1-3 flex justify-center align-center height-100">
              <Dm />
            </div>
            <div className="height-4em flex-column align-start space-between width-2-3">
              <p className="margin-0">Lazarus</p>
              <p className="margin-0">Clinical Decision Support</p>
              <p className="bold margin-0">Dermatology</p>
            </div>
          </header>
          <form
            onChange={this.onChange}>
            <label>First Name</label>
            <input
              name="First Name"
              style={this.giveRedBorder('First Name')}
            />
            <label>Last Name</label>
            <input
              name="Last Name"
              style={this.giveRedBorder('Last Name')}
            />
            <label>Email</label>
            <input
              name="Email"
              style={this.giveRedBorder('Email')}
            />
            <label>Phone Number</label>
            <input
              name="Phone Number"
              style={this.giveRedBorder('Phone Number')}
            />
            <select
              name="Sex"
              id="sex-select"
              style={this.giveRedBorder('Sex')}
            >
              <option value="Male">Male</option>
              <option value="Female">Female</option>
              <option value="Other">Other</option>
            </select>
            <div className="width-45 birthday">
              <label>Birthday (mm/dd/yyyy)</label>
              <br></br>
              {/*<input className="text-input" style={{ height: '33px' }} type="date" placeholder="yyyy-mm-dd" name="birthday" onChange={this.handleChange}/>*/}
              <input
                className="text-input two-digit date-input month"
                id="month"
                type="number"
                max="12"
                min="1"
                placeholder="mm"
                name="month"
                onChange={this.handleChange}
                style={this.giveRedBorderBirthday('month')}
              />
              <span>/</span>
              <input
                className="text-input two-digit date-input day"
                id="day"
                type="number"
                max="31"
                min="1"
                placeholder="dd"
                name="day"
                onChange={this.handleChange}
                style={this.giveRedBorderBirthday('day')}
              />
              <span>/</span>
              <input
                className="text-input four-digit date-input year"
                id="year"
                type="number"
                min="1900"
                placeholder="yyyy"
                name="year"
                onChange={this.handleChange}
                style={this.giveRedBorderBirthday('year')}
              />
            </div>
          </form>
          <div>
            {this.state.error &&
              <p style={{ color: 'red', margin: '1em' }}>
                {this.state.error}
              </p>
            }
            <div
              className="btn-div"
              style={{margin: '1em'}}
              onClick={this.onSubmit}
            >
              <p>Submit</p>
            </div>
          </div>
        </Fragment>
        }
      </div>
    )
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
})

export default connect(mapStateToProps, { storePatientData })(PatientForm)