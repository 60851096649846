import React from "react";
import { ElementsConsumer } from '@stripe/react-stripe-js';
import CheckoutForm from './CheckoutForm'

function InjectedCheckoutForm(props) {
  return (
    <ElementsConsumer>
      {({ stripe, elements }) => (
        <CheckoutForm stripe={stripe} elements={elements} changePage={props.changePage} />
      )}
    </ElementsConsumer>
  )
}

export default InjectedCheckoutForm;