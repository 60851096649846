import React, { Component } from 'react'
import { BrowserRouter } from 'react-router-dom'
import {connect} from 'react-redux'
import {createFirebaseApp} from './firebaseAction'
import {storeLocalStorageTestSets} from './storeLocalStorageTestSetsAction.js'
import queryString from 'query-string';
import Instructions from './Instructions3'
import Checklist from './Checklist2'
import ImageUpload from './ImageUpload'
import BodyPart from './BodyPart'
import Collage from './Collage'
import TestSet from './TestSet'
import LoadingScreen from './LoadingScreen'
import Result from './Result'
import Error from './Error'
import PatientForm from './PatientForm'
import LoadingIcon from './LoadingIcon'
import {storePatientData} from './storePatientDataAction'
import Pricing from './Pricing'

import 'firebase/auth'
import 'firebase/database'
import 'firebase/firestore'
import 'firebase/storage'


class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      patientName: 'Patient Name',
      pricingModal: false,
      slideIn: false, // modal animation
      orientationCounter: 0,
      pages: {
        instructionsPage: true,
        checklistPage: false,
        collagePage: false,
        bodyPartPage: false,
        bodyPartPage2: false, // zoomed in view
        testSetPage: false,
        imageUploadPage: false,
        cropperPage: false,
        loadingScreenPage: false,
        resultPage: false,
        errorPage: false, // if query string doesn't have necessary variables
        patientFormPage: false
      }
    }
  }

  changePage = (pageToRender) => {
    const pages = Object.keys(this.state.pages)
    const newState = {}
    pages.forEach(page => {
      if (page === pageToRender) {
        newState[page] = true
      } else {
        newState[page] = false
      }
    })
    this.setState({...this.state, pages: {...newState}} )
  }

  setAppState = (newState) => {
    this.setState({
      ...this.state,
      ...newState
    })
  }

  componentDidMount() {
    let check = queryString.parse(window.location.search).key
    if (check != "LazDermFirst") {
      window.open("https://patient.lazarusderm.com/" + window.location.search, "_self")
    }
    this.props.createFirebaseApp()

    // Resizing functionality
    let height = window.innerHeight
    let width = window.innerWidth
    this.setState({height, width})
    const resize = () => {
      if (window.orientation === 90 || window.orientation === -90) {
        height = window.innerWidth
        width = window.innerHeight
      } else {
        height = window.innerHeight
        width = window.innerWidth
      }
      
      this.setState({height, width})
      document.getElementById('root').style.width = width
      document.getElementById('root').style.height = height
    }
    window.addEventListener('resize', resize)
    window.addEventListener('orientationchange', () => {
      this.setState({orientationCounter: this.state.orientationCounter + 1}) // trigger new render
      resize()
    })
    resize()

    // Code for using local storage to retrieve complete test sets

    // const storageStateJSON = window.localStorage.getItem('storageState')
    // let storageState
    // if (storageStateJSON) {
    //   storageState = JSON.parse(storageStateJSON)
    // }
    // if (storageState) {
    //   console.log('storage state', storageState)
    // }
    // const testSets =
    //   storageState &&
    //   storageState.testSets &&
    //   storageState.testSets.length > 0 ? 
    //   storageState.testSets.filter(testSet => testSet.croppedImageDataURLS.length === 3 && testSet.croppedImageDataURLS[0]) : 
    //   []
    // console.log('test sets to be stored from local storage', testSets)
    // this.props.storeLocalStorageTestSets(testSets)
  }

  componentWillReceiveProps(props) {
    // Check query parameters
    
    const patient = queryString.parse(window.location.search).patient
    // const patientObj = {patient, from: 'ur butt'}
    const state = queryString.parse(window.location.search).state
    const batchId = queryString.parse(window.location.search).batchId

    if (!state) {
      this.changePage('errorPage')
    } else if (!batchId && !patient) {
      this.changePage('patientFormPage')
    } else if (!this.props.patientData || this.props.patientData === {}) {
      console.log('fetching patient data')
      this.setState({isLoading: true})
      // check if patient exists in database
      if (Object.keys(props.firestore).length > 0) {
        props.firestore.collection('OnlinePatients').doc(patient).get()
          .then((docSnapshot) => {
            if (docSnapshot.exists) { 
              const data = docSnapshot.data()
              const name = data["name"]
              this.props.storePatientData(data, name, patient)
              this.setState({patientName: name, isLoading: false})
            } else {
              this.setState({isLoading: false})
              this.changePage('patientFormPage')
            }
          }).catch((error) => {
            console.log("error", error)
            this.setState({isLoading: false})
            this.changePage('patientFormPage')
          })
        } else {
          console.log('no firestore yet')
        }
      } else {
        console.log('skipping the patient fetch')
        this.setState({isLoading: false})
      }
  }

  togglePage = (pageToToggle) => {
    this.setState({...this.state, pages: {...this.state.pages, [pageToToggle]: !this.state.pages[pageToToggle]}})
  }

  // hidePage = (pageToHide) => {
  //   const pages = Object.keys(this.state.pages)
  //   const newState = {}
  //   pages.forEach(page => {
  //     if (page === pageToHide) {
  //       newState[page] = false
  //     } else {
  //       newState[page] = true
  //     }
  //   })
  //   this.setState({...this.state, pages: {...newState}} )
  // }

  render() {
    document.getElementById('root').style.minHeight = `${this.state.height}px`
    return (
      <BrowserRouter>
      <div
        className="App"
        id="App"
        style={{ width: `${this.state.width}px`, minHeight: `${this.state.height}px`}}
      >
        {this.state.pages.instructionsPage &&
          <Instructions
            changePage={this.changePage.bind(this)}
            setAppState={this.setAppState}
          />
        }
        {this.state.pages.checklistPage &&
          <Checklist
            changePage={this.changePage.bind(this)}
            togglePage={this.togglePage.bind(this)}
            pages={this.state.pages}
          />
        }
        {this.state.pages.imageUploadPage &&
          <ImageUpload 
            changePage={this.changePage.bind(this)}
          />
        }
        {this.state.pages.bodyPartPage &&
          <BodyPart
            changePage={this.changePage.bind(this)}
          />
        }
        {this.state.pages.collagePage &&
          <Collage 
            changePage={this.changePage.bind(this)}
          />
        }
        {this.state.pages.testSetPage &&
          <TestSet
            changePage={this.changePage.bind(this)}
            pages={this.state.pages}
          />
        }
        {this.state.pages.loadingScreenPage &&
          <LoadingScreen
            changePage={this.changePage.bind(this)}
          />
        }
        {this.state.pages.resultPage &&
          <Result
            changePage={this.changePage.bind(this)}
          />
        }
        {this.state.pages.patientFormPage &&
          <PatientForm
            changePage={this.changePage.bind(this)}
          />
        }
        {this.state.pages.errorPage &&
          <Error />
        }
        {this.state.isLoading &&
          <LoadingIcon />
        }
        {this.state.pricingModal &&
          <Pricing
            slideIn={this.state.slideIn}
            setAppState={this.setAppState}
            changePage={this.changePage}
          />
        }
      </div>
      </BrowserRouter>
    );
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  patientData: state.imageReducer.patientData
})

export default connect(mapStateToProps, {createFirebaseApp, storePatientData, storeLocalStorageTestSets})(App)
