import React from 'react'
import {connect} from 'react-redux'
import LazarusLogo from './LazarusLogo'
import Dm from './Dm.js'
import checkMark from './Check.svg'
import warning from './Warning.svg'
import {deleteTestSet} from './deleteTestSetAction'

function Result(props) {
  if (props.batchUploadResponse === 'success') {
    props.testSets.forEach((testSet, i) => {
      props.deleteTestSet(i)
    })
  }
  return (
    <div
      className="container1"
      id="result-container"
      // style={{ height: `${window.innerHeight}px`}}
      style={{ minHeight: `${window.innerHeight}px` }}
    >
      <header className="flex-column space-evenly">
        <LazarusLogo />
        <div className="flex space-between align-center">
          <Dm />
          <div id="mobitest">
            <p>M-PROC</p>
          </div>
        </div>
        <div className="height-4em flex-column align-start space-between">
          <p className="margin-0">Lazarus</p>
          <p className="margin-0">Clinical Decision Support</p>
          <p className="bold margin-0">Dermatology</p>
        </div>
      </header>
      <main className="result-main flex-column justify-center">
        <div className="results-container">
          <div className="circle-container flex justify-center align-center">
            {props.batchUploadResponse === 'success' ?
            <div className="green-circle flex justify-center align-center m">
              <img src={checkMark} alt="check mark" />
            </div> :
            <div className="flex justify-center align-center m">
              <img src={warning} alt="warning" />
            </div>
            }
          </div>
          {props.batchUploadResponse === 'success' ?
          <h1 className="n">Uploaded</h1> :
          <h1 className="n">Warning</h1>
          }
          <div className="o">
            {props.batchUploadResponse === 'success' ?
            <p>Your images have been securely uploaded</p> :
            <p>There was an error. Please try again later</p>
            }
          </div>
        </div>
      </main>
      <footer className="grey-footer flex justify-center align-center">
        {props.batchUploadResponse !== 'success' ?
        <div className="btn-div" onClick={() => props.changePage('collagePage')}>
          <p>Back to Tests</p>
        </div> :
        <div className="btn-div" onClick={() => props.changePage('instructionsPage')}>
          <p>Submit more tests</p>
        </div>
        }
      </footer>
    </div>
  );
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  batchUploadResponse: state.imageReducer.batchUploadResponse,
  testSets: state.imageReducer.testSets
})

export default connect(mapStateToProps, {deleteTestSet})(Result)