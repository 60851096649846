export const createTestSet = (time, moleLocation, moleCoordinates, front) => dispatch => {
  dispatch({
    type: 'CREATE_TESTSET',
    payload: {
      time: time,
      croppedImageUrls: [],
      croppedImageFiles: [],
      croppedImageDataURLS: [],
      moleCoordinates: moleCoordinates,
      moleLocation: moleLocation,
      front: front
    }
  })
}