import React, { Fragment, Component } from 'react'
import {connect} from 'react-redux'
import loadImage from '../node_modules/blueimp-load-image/js'
import ReactCrop from 'react-image-crop'
import { createTestSet } from './createTestSetAction'

class BodyPart2 extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gender: 'female',
      front: true,
      cropHasBeenAdjusted: false,
      crop: {
        unit: '%',
        // imgRatio: 1.45667,
        trueRatio: this.props.trueRatio,
        // height: 17.1624, // width / imgRatio = height
        height: 25 / this.props.trueRatio,
        width: 25,
        // aspect: 1 / 1,
        x: 37.5,
        // y: 41.4188 // (100 - height) / 2,
        y: (100 - (25 / this.props.trueRatio)) / 2
      },

      minWidth: null,
      maxWidth: null,
      isImageSelected: false,
      imageFile: [],
      imageUrl: [],
      croppedImageFiles: [],
      croppedImageUrls: [],
      croppedImageFile: null,
      croppedImageUrl: null,
      isImageCropped: false,

      loading: false,
      tooSmall: false,
      showCollage: false
    }
  }

  onClickAddImage = (event) => {
    this.props.storeImageIndex(null)
    this.onSelectImage(event)
  }

  onClickNext = () => {
    console.log('to do: handle click next')
  }

  setImage = () => {
    loadImage(this.props.croppedImageUrl, (img) => {
      const blobPromise = new Promise((resolve, reject) => {
        if (img.height < 480 || img.width < 480) {
          // alert("Warning: Image should be at least 480 x 480 pixels")
          this.setState({ tooSmall: true })
        } else {
          this.setState({ tooSmall: false })
        }
        img.toBlob(blob => {
          if (!blob) {
            console.error('Canvas is empty');
            return;
          }
          blob.name = 'fileName';
          this.fileUrl = window.URL.createObjectURL(blob);
          resolve([this.fileUrl, blob]);
        }, 'image/jpeg');
      });

      blobPromise
        .then((imageArr) => {
          this.setState({
            front: !this.state.front,
            isImageSelected: true,
            imageUrl: imageArr[0],
            imageFile: imageArr[1],
            isImageCropped: false,
            showCollage: false
          })
        })
    }, { orientation: true })
  }

  // toggleSelecting = (percentCrop) => {
  //   setTimeout(() => {
  //     const time = Date.now()
  //     if ((time - this.state.time) >= 2000) {
  //       this.setState({ selecting: false })
  //       this.onCropChange(percentCrop)
  //     }
  //   }, 2000);
  // }

  // handleSelecting = (percentCrop) => {
  //   const time = Date.now()
  //   this.setState({ crop: { ...this.state.crop, ...percentCrop}, time, selecting: true })
  //   this.toggleSelecting(percentCrop)
  // }

  onImageLoaded = image => {
    this.imageRef = image;
  }

  onCropChange = (crop, percentCrop) => {
    // this.handleSelecting(percentCrop)
    this.setState({ crop: percentCrop })
    const centerX = percentCrop.x + (percentCrop.width / 2)
    const centerY = percentCrop.y + (percentCrop.height / 2)

    this.props.adjustCoordinatesFromZoom(centerX, centerY)
  }

  generateBodyPart = (x, y) => {
    const centerX = x + (this.state.crop.width / 2) // x and y coordinates correspond to upper left corner of selector
    const centerY = y + (this.state.crop.width / 2) // centerX and centerY correspond to center of selector
    
    // y coordinates of body parts
    const chinYLine = 17
    const torsoTopLine = 20
    const abdomenTopLine = 35
    const legTopLine = 48
    const footTopLine = 90
    const handTopLine = 48
    const handBottomLine = 58
    const armTopLine = 20

    // x coordinate of body parts
    const midLine = 50
    const headXLine = 7
    const outsideLegXLine = 30
    const insideHandXLine = 25
    const outsideFootXLine = 20
    let shoulderXLine
    if (this.state.gender === 'female') {
      shoulderXLine = 10
    } else {
      shoulderXLine = 15
    }

    // Gaps between body parts
    if ((centerX < midLine + 8) && (centerX > midLine - 8) && (centerY < 100) && centerY > 80) {
      return '' // Between feet
    }
    if ((centerX < midLine + 5) && (centerX > midLine - 5) && (centerY <= 80) && centerY > 65) {
      return '' // Between legs
    }
    if (((centerX < (midLine - headXLine) || centerX > (midLine + headXLine)) && centerY > 0 && centerY < 20)) {
      return ''  // Right and left of head
    }
    if (((centerX < (midLine - 25) || centerX > (midLine + 25)) && centerY > 20 && centerY < 35)) {
      return ''  // Right and left of shoulders
    }

    if ((centerX < (midLine + headXLine) && centerX > (midLine - headXLine)) && (centerY > chinYLine && centerY < 20)) {
      return 'neck'
    }
    if ((centerX < (midLine + headXLine) && centerX > (midLine - headXLine)) && (centerY > 0 && centerY < chinYLine)) {
      if (!this.state.front) {
        return 'face'
      } else {
        return 'head'
      }
    } else if (centerX < (midLine + shoulderXLine) && centerX > (midLine - shoulderXLine) && (centerY > torsoTopLine && centerY < abdomenTopLine)) {
      if (!this.state.front) {
        return 'torso'
      } else {
        return 'back'
      }
    } else if (centerX < (midLine + shoulderXLine) && centerX > (midLine - shoulderXLine) && (centerY > abdomenTopLine && centerY < legTopLine)) {
        if (!this.state.front) {
          return 'abdomen'
        } else {
          return 'lower back'
        }
    } if (centerY > footTopLine) {
        if (centerX < midLine && centerX > outsideFootXLine) {
          if (!this.state.front) {
            return 'right foot'
          } else {
            return 'left foot'
          }
        } else if (centerX > midLine && centerX < (100 - outsideFootXLine)) {
          if (!this.state.front) {
            return 'left foot'
          } else {
            return 'right foot'
          }
        }
    } else if ((centerY > legTopLine)) {
        if (centerX < midLine && centerX > outsideLegXLine) {
          if (!this.state.front) {
            return 'right leg'
          } else {
            return 'left leg'
          }
        } else if (centerX > midLine && centerX < (100 - outsideLegXLine)) {
          if (!this.state.front) {
            return 'left leg'
          } else {
            return 'right leg'
          }
        }
     } if (centerY > handTopLine && centerY < handBottomLine) {
        if (centerX < (midLine - insideHandXLine)) {
          if (!this.state.front) {
            return 'right hand'
          } else {
            return 'left hand'
          }
        } else if (centerX > (midLine + insideHandXLine)) {
          if (!this.state.front) {
            return 'left hand'
          } else {
            return 'right hand'
          }
        }
     } if (centerY > armTopLine && centerY < handTopLine) {
        if (centerX < (midLine - shoulderXLine)) {
          if (!this.state.front) {
            return 'right arm'
          } else {
            return 'left arm'
          }
       } else if (centerX > (midLine + shoulderXLine)) {
         if (!this.state.front) {
           return 'left arm'
         } else {
           return 'right arm'
         }
       } else {
         return ''
       }
     } else {
       return ''
    }
  }



  onCropComplete = crop => {
    const adjustedCrop = {...crop, width: 100, height: 100}
    this.makeClientCrop(adjustedCrop);
  }

  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImage = await this.getCroppedImg(
        this.imageRef,
        crop,
        'newFile.jpeg'
      );
      const newCroppedImageUrl = croppedImage[0]
      const newCroppedImageFile = croppedImage[1]

      this.setState({
        croppedImageUrl: newCroppedImageUrl,
        croppedImageFile: newCroppedImageFile
      });
    }
  }

  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement('canvas');
    const maxWidth = 960
    const shrinkRatio = maxWidth / image.naturalWidth
    
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;

    if (image.naturalWidth > maxWidth) {
      canvas.width = Math.floor(crop.width * scaleX * shrinkRatio);
      canvas.height = Math.floor(crop.height * scaleY * shrinkRatio);
    } else {
      canvas.width = Math.floor(crop.width * scaleX);
      canvas.height = Math.floor(crop.height * scaleY);
    }
    const ctx = canvas.getContext('2d')

    /* Math for guaranteeing minimum size of 480 x 480 */
    let minWidth

    if (image.naturalWidth > maxWidth) {
      minWidth =  480 / scaleX / shrinkRatio
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX * shrinkRatio,
        crop.height * scaleY * shrinkRatio,
      );
  
    } else {
      minWidth =  480 / scaleX
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width * scaleX,
        crop.height * scaleY,
      );
    }

    this.setState({ minWidth })

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          reject(new Error('Canvas is empty'));
        }
        blob.name = fileName;
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve([this.fileUrl, blob]);
      }, 'image/jpeg');
    });
  }

  onClickDone() {
    const time = Date.now()
    const x = this.state.crop.x
    const y = this.state.crop.y
    const centerX = x + (this.state.crop.width / 2) // x and y coordinates correspond to upper left corner of selector
    const centerY = y + (this.state.crop.width / 2) // centerX and centerY correspond to center of selector
    const moleLocation = this.generateBodyPart(x, y)
    const moleCoordinates = [centerX, centerY]
    this.props.createTestSet(time, moleLocation, moleCoordinates)
    // this.props.changePage('collagePage')

    // if (this.props.indexOfImageToReplace !== null) { // Image is being replaced
    //   console.log('index of image to replace', this.props.indexOfImageToReplace)
    //   this.props.spliceImage(this.state.croppedImageUrl, this.state.croppedImageFile, this.props.indexOfImageToReplace)
    // } else { // Image is being uploaded
    //   this.props.storeImages(this.state.croppedImageUrl, this.state.croppedImageFile)
    // }
    // }
  }

  onClickCancelCrop = (event) => {
    event.stopPropagation()
    this.state.isImageCropped
    ? this.setState({
      croppedImageUrl: null,
      croppedImageFile: null,
      isImageCropped: false
    })
    : this.setState({
      cropHasBeenAdjusted: false,
      croppedImageUrl: null,
      croppedImageFile: null,
      imageUrl: [],
      imageFile: [],
      isImageSelected: false,
      crop: {
        unit: '%',
        height: 100,
        width: 100,
        aspect: 1 / 1
      }
    })
    // document.getElementById('AddImageInput').value = null
  }

  render() {
    const cropper = !this.state.isImageCropped ?
      <ReactCrop
        className='crop-image'
        src={this.props.croppedImageUrl}
        crop={this.state.crop}
        ruleOfThirds={false}
        locked={true}
        onImageLoaded={this.onImageLoaded}
        onComplete={this.onCropComplete}
        onChange={this.onCropChange}
        minWidth={this.state.minWidth}
        minHeight={this.state.minWidth}
        maxWidth={this.state.maxWidth}
        maxHeight={this.state.maxHeight}
        keepSelection={true}
        circularCrop={true}
      />
    : <img className='crop-image' src={this.state.croppedImageUrl} alt=""></img>

    const cropperContainer = (
      <div className="cropper-container">
        <div className="image-container zoomed-in">
        {cropper}
        </div>
      </div>
    )

    // const selectedPart = this.generateBodyPart(this.state.crop.x, this.state.crop.y)

    return (
      <Fragment>
        {cropperContainer}
      </Fragment>
    )
  }
}

const mapStateToProps = state => ({
  storage: state.firebaseReducer.storage,
  database: state.firebaseReducer.database,
  firestore: state.firebaseReducer.firestore,
  testSets: state.imageReducer.testSets
})

export default connect(mapStateToProps, { createTestSet })(BodyPart2)