import React from "react";

function LazarusLogo() {
  return (
    <svg
      id="lazarus-logo"
      xmlns="http://www.w3.org/2000/svg"
      width="264"
      height="76"
      viewBox="0 0 264 76"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill="#000">
          <path d="M82.0001173 45.3402914L82.0001173 48.87215 68.0001173 48.87215 68.0001173 27.87215 71.9752751 27.87215 71.9752751 45.3402914z"></path>
          <path d="M102.187 40.504l-1.82-5.918-.882-3.182h-.059l-.822 3.118-1.88 5.982h5.463zm.998 3.246h-7.459l-1.585 5.122H90l7.076-21h4.845l7.078 21h-4.2l-1.615-5.122z"></path>
          <path d="M134.000117 45.37215L134.000117 48.87215 117.000117 48.87215 117.000117 45.5629978 128.921707 31.37215 117.247663 31.37215 117.247663 27.87215 133.62865 27.87215 133.62865 31.1813023 121.706762 45.37215z"></path>
          <path d="M153.187 40.504l-1.82-5.918-.882-3.182h-.058l-.823 3.118-1.88 5.982h5.463zm.998 3.246h-7.459l-1.585 5.122H141l7.076-21h4.846l7.077 21h-4.2l-1.615-5.122z"></path>
          <path d="M172.569 37.122h4.167c1.216 0 2.114-.238 2.692-.715.578-.476.867-1.21.867-2.205 0-.993-.29-1.728-.867-2.205-.578-.476-1.476-.715-2.692-.715h-4.167v5.84zm3.771 3.194h-3.771v7.695h-4.138V27.937h8.852c2.292 0 4.081.542 5.37 1.627 1.287 1.085 1.931 2.61 1.931 4.577 0 1.44-.355 2.646-1.065 3.62-.71.973-1.724 1.673-3.042 2.098l5.293 8.152h-4.714l-4.716-7.695z"></path>
          <path d="M211.444 40.103c0 2.717-.726 4.765-2.175 6.144-1.45 1.379-3.585 2.068-6.403 2.068-2.82 0-4.954-.69-6.404-2.068-1.45-1.379-2.175-3.427-2.175-6.144V27.937h4.198V39.92c0 1.684.36 2.93 1.08 3.741.72.811 1.82 1.217 3.3 1.217 1.48 0 2.576-.406 3.286-1.217.71-.81 1.065-2.057 1.065-3.74V27.936h4.228v12.166z"></path>
          <path d="M233.954 28.56c1.338.619 2.514 1.597 3.528 2.935l-2.89 2.829c-.527-1.095-1.227-1.911-2.099-2.448-.872-.537-1.977-.806-3.315-.806-1.217 0-2.155.227-2.814.684-.66.456-.989 1.06-.989 1.81 0 .69.335 1.236 1.004 1.642.67.406 1.876.76 3.62 1.064 1.825.346 3.275.771 4.35 1.278 1.075.507 1.86 1.14 2.357 1.9.497.761.746 1.71.746 2.844 0 1.238-.35 2.308-1.05 3.21-.7.902-1.673 1.596-2.92 2.083-1.247.486-2.672.73-4.274.73-2.21 0-4.02-.31-5.43-.928a7.986 7.986 0 01-3.422-2.813l2.495-2.98c.75 1.236 1.612 2.093 2.585 2.57.974.476 2.18.714 3.62.714 1.298 0 2.286-.202 2.966-.608.679-.406 1.019-.984 1.019-1.734 0-.547-.32-1.004-.958-1.369-.64-.365-1.78-.71-3.422-1.034-1.887-.385-3.392-.841-4.517-1.368-1.126-.528-1.943-1.176-2.45-1.947-.507-.77-.76-1.723-.76-2.86 0-1.195.324-2.275.974-3.238.648-.963 1.597-1.719 2.844-2.266 1.247-.548 2.712-.822 4.395-.822 1.866 0 3.468.31 4.807.928"></path>
          <path d="M256.714 34.123l-.964-2.893-.467-1.555h-.03l-.436 1.524-.995 2.924h2.892zm.53 1.586h-3.951l-.84 2.504h-2.193l3.748-10.265h2.566l3.75 10.265h-2.225l-.856-2.504z"></path>
          <path d="M261.847637 38.2133558L263.993957 38.2133558 263.993957 27.9486956 261.847637 27.9486956z"></path>
          <path d="M15.49064 69.7766361L17.4374933 56.3170465 29.69472 49.2411563 45.5737333 58.4079903 15.1911467 75.9473989 -8.52651283e-14 67.1779879 -8.52651283e-14 8.76941098 15.1911467 -2.13162821e-14 15.1911467 69.7766361z"></path>
        </g>
      </g>
    </svg>
  );
}

export default LazarusLogo;